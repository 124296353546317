import './projectCard.scss';

const ProjectCard = ({ color = '#E6E6E6', img, category, subcategory, title, description, large = false }) => {
    //check if platform is mobile
    const isMobile = window.innerWidth < 500;
    const isPad = window.innerWidth < 1280 && window.innerWidth >= 768;
    const smallWidth = isMobile ? '100%' : (isPad ? '460px' : '614px');
    const height = large ? '192px' : '315px';

    return (
        <div style={{
            width: large ? '100%' : smallWidth,
            height: isMobile ? height : 'auto'
        }} className="project-card">
            <div className="project-card__category">
                <div className="project-card__category__icon" />
                <span>{category}</span>
                {subcategory && [
                    <div className="project-card__category__separator" />,
                    <span>{subcategory}</span>
                ]}
            </div>
            <img width='100%' height='100%' src={img} alt={title} />
            <div className="project-card__text">
                <h3 style={{ color: color }}>{title}</h3>
            </div>
        </div>
    );
} 

export default ProjectCard;