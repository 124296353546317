import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { Footer, Header } from './components'
import { MainPage, CasesPage, TermsPage, FormPage, FormSent, ErrorPage } from './pages';
import ScrollToTop from './utils/scrollToTop';
import CookieConsent from './components/common/cookieConsent/cookieConsent';
import CookiesPage from './pages/terms/cookiesPage';

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/cases" element={<CasesPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/cookies" element={<CookiesPage />} />
                <Route path='/form' element={<FormPage />} />
                <Route path='/form-sent' element={<FormSent />} />
                <Route path='/form-error' element={<ErrorPage />} />
            </Routes>
            <Footer />
            <CookieConsent />
        </BrowserRouter>
    );
}

export default App;
