import { ProjectCard } from '../../components';
import './mainPage.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import appsIcon from '../../assets/images/title/apps.svg';
import brandingIcon from '../../assets/images/title/branding.svg';
import brushIcon from '../../assets/images/title/brush.svg';
import calendarIcon from '../../assets/images/title/calendar.svg';
import dataScienceIcon from '../../assets/images/title/data-science.svg';
import developmentIcon from '../../assets/images/title/development.svg';
import hardwareIcon from '../../assets/images/title/hardware.svg';
import researchIcon from '../../assets/images/title/research.svg';
import strategyIcon from '../../assets/images/title/strategy.svg';
import systemDesignIcon from '../../assets/images/title/system-design.svg';
import industrialIcon from '../../assets/images/title/industrial.svg';

import browserImage from '../../assets/images/projects/browser.png';
import wallet from '../../assets/images/projects/wallet.png';
import notes from '../../assets/images/projects/ai-notes.png';
import gameStore from '../../assets/images/projects/game-store.png';
import marketplace from '../../assets/images/projects/marketplace.png';
import messanger from '../../assets/images/projects/messanger.png';
import taskAi from '../../assets/images/projects/task-ai.png';
import websiteDeos from '../../assets/images/projects/website-deos.png';

import customDevice from '../../assets/images/industrial-design/custom-device.png';
import industrialControllerCase from '../../assets/images/industrial-design/industrial-controller-case.png';
import multitool from '../../assets/images/industrial-design/multitool.png';
import reductor from '../../assets/images/industrial-design/reductor.png';

import playIcon from '../../assets/images/about/play.svg';
import handPeaceIcon from '../../assets/images/about/hand-peace.svg';

import DreamBuildLogo from '../../assets/images/trusted/dreambuild.svg';
import FileCoinLogo from '../../assets/images/trusted/filecoin.svg';
import InBoxLogo from '../../assets/images/trusted/inbox.svg';
import laMoukaLogo from '../../assets/images/trusted/mouka.png';
import NanosondyLogo from '../../assets/images/trusted/nanosondy.cz.svg';
import RADALogo from '../../assets/images/trusted/rada.svg';
import WalletLogo from '../../assets/images/trusted/wallet.svg';
import deosLogo from '../../assets/images/trusted/deos.svg';
import srdcovkaLogo from '../../assets/images/trusted/srdcovka.png';
import residenceLogo from '../../assets/images/trusted/residence.png';

import buildIcon from '../../assets/images/evolve/build.svg';
import boxesIcon from '../../assets/images/evolve/boxes.svg';
import improveIcon from '../../assets/images/evolve/improve.svg';

import bezierIcon from '../../assets/images/dev-process/bezier.svg';
import hamsaIcon from '../../assets/images/dev-process/hamsa.svg';
import launchIcon from '../../assets/images/dev-process/launch.svg';
import terminalIcon from '../../assets/images/dev-process/terminal.svg';

import Post1 from '../../assets/images/posts/post-1.png';
import Post2 from '../../assets/images/posts/post-2.png';
import Post3 from '../../assets/images/posts/post-3.png';

import stackRow1 from '../../assets/images/stack/row1.png';
import stackRow2 from '../../assets/images/stack/row2.png';
import stackRow3 from '../../assets/images/stack/row3.png';

import Marquee from 'react-fast-marquee';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const MainPage = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = window.innerWidth < 500;
    const isPad = window.innerWidth < 2196 && window.innerWidth >= 768;
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth);
        })
    }, []);

    useEffect(() => {
        console.log(width);
    }, [width]);

    return (
        <div className="main">
            <section className="title">
                <div className="title__container">
                    <div className='title__text'>
                        <h1 className='title__text-black'>Design &</h1>
                        <h1 className='title__text-grey'>Development Studio</h1>
                        <div className="polygon" />
                    </div>
                    {(isMobile) ? (
                        <div className="marquee-container">
                            <Marquee
                                direction='left'
                                speed={20}
                            >
                                <div className="title__button"><img src={systemDesignIcon} className="icon" />System Design</div>
                                <div className="title__button"><img src={researchIcon} className="icon" />Research & Analysis</div>
                                <div className="title__button"><img src={appsIcon} className="icon" />Websites & Apps</div>
                                <div className="title__button"><img src={strategyIcon} className="icon" />Analytics & Strategy</div>
                            </Marquee>
                            <Marquee
                                direction='right'
                                speed={22}
                            >
                                <div className="title__button"><img src={developmentIcon} className="icon" />Custom full-stack development</div>
                                <div className="title__button"><img src={brandingIcon} className="icon" />Branding</div>
                                <div className="title__button"><img src={dataScienceIcon} className="icon" />Data Science & Machine Learning</div>
                            </Marquee>
                            <Marquee
                                direction='left'
                                speed={30}
                            >
                                <div className="title__button"><img src={brushIcon} className="icon" />UX/UI Design</div>
                                <div className="title__button"><img src={hardwareIcon} className="icon" />Hardware</div>
                                <div className="title__button"><img src={calendarIcon} className="icon" />MVP Launch</div>
                                <div className="title__button"><img src={industrialIcon} className="icon" />Industrial Design</div>
                            </Marquee>
                        </div>
                    ) : (
                        <div className="title__btn-container">
                            <div className="title__button"><img src={systemDesignIcon} className="icon" />System Design</div>
                            <div className="title__button"><img src={researchIcon} className="icon" />Research & Analysis</div>
                            <div className="title__button"><img src={appsIcon} className="icon" />Websites & Apps</div>
                            <div className="title__button"><img src={strategyIcon} className="icon" />Analytics & Strategy</div>
                            <div className="title__button"><img src={developmentIcon} className="icon" />Custom full-stack development</div>
                            <div className="title__button"><img src={brandingIcon} className="icon" />Branding</div>
                            <div className="title__button"><img src={dataScienceIcon} className="icon" />Data Science & Machine Learning</div>
                            <div className="title__button"><img src={brushIcon} className="icon" />UX/UI Design</div>
                            <div className="title__button"><img src={hardwareIcon} className="icon" />Hardware</div>
                            <div className="title__button"><img src={calendarIcon} className="icon" />MVP Launch</div>
                        </div>
                    )}
                </div>
            </section>
            <section className='projects'>
                <h1 className='projects__title'>Our Projects</h1>
                <div className="projects__card-container">
                    <ProjectCard
                        key={1}
                        title='Ai Browser'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Software package'
                        large
                        img={browserImage}
                    />
                    <ProjectCard
                        key={2}
                        title='Wallet'
                        category='Full-stack development'
                        description='Online service'
                        img={wallet}
                    />
                    <ProjectCard
                        key={3}
                        title='3D assets marketplace'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Engineering, 3d modelling'
                        img={marketplace}
                    />
                    <ProjectCard
                        key={6}
                        title='Ai-powered notes'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Online service'
                        img={notes}
                    />
                    {!isMobile && ([
                        <ProjectCard
                            key={9}
                            title='Game Store'
                            category='UX / UI'
                            subcategory='Full-stack development'
                            description='Online service'
                            img={gameStore}
                        />,
                        <ProjectCard
                            key={10}
                            title='Messanger'
                            category='UX / UI'
                            subcategory='Full-stack development'
                            description='Cross-platform development'
                            large
                            img={messanger}
                        />,
                        <ProjectCard
                            key={11}
                            title='Task.ai'
                            category='UX / UI'
                            subcategory='Full-stack development'
                            description='Online service'
                            img={taskAi}
                            color='#292929'
                        />,
                        <ProjectCard
                            key={12}
                            title='Website deos.cc'
                            category='UX / UI'
                            subcategory='Full-stack development'
                            description='Online service'
                            img={websiteDeos}
                            color='#292929'
                        />
                    ])}
                </div>
                <div className="btn-all-cases" onClick={() => navigate('/cases')}>
                    <span>All cases</span>
                    <div className="icon" />
                </div>
            </section>
            {!isMobile && (
                <section className="industrial-design">
                    <h1>Industrial design</h1>
                    <div className="industrial-design__card-container">
                        <ProjectCard
                            key={13}
                            title='Industrial controller case'
                            category='Hardware Device'
                            description='Engineering, 3d modelling'
                            img={industrialControllerCase}
                            color='#292929'
                        />
                        <ProjectCard
                            key={14}
                            title='Reductor'
                            category='Hardware Device'
                            description='Engineering, 3d modelling'
                            img={reductor}
                            color='#292929'
                        />
                        <ProjectCard
                            key={15}
                            title='Multitool'
                            category='Hardware Device'
                            description='Engineering, 3d modelling'
                            img={multitool}
                            color='#292929'
                        />
                        <ProjectCard
                            key={16}
                            title='Custom device'
                            category='Hardware Device'
                            description='Engineering, 3d modelling'
                            img={customDevice}
                            color='#292929'
                        />
                    </div>
                    <div className="btn-all-cases" onClick={() => navigate('/cases')}>
                        <span>All cases</span>
                        <div className="icon" />
                    </div>
                </section>
            )}
            <section className="about">
                <h1>About us</h1>
                <p>From our Prague base, we weave technology and design into digital artistry. With each project, we blend advanced tech with intuitive design, crafting experiences that resonate with users and set industry benchmarks.  <br />
                    <br />
                    We pride ourselves on a track record of projects that are as successful as they are aesthetically pleasing.
                </p>
                <div className="about__buttons">
                    <div className="about__button"><img src={handPeaceIcon} /><h5>Intro presentation</h5></div>
                    <div className="about__button"><img src={playIcon} /><h5>Watch showreel</h5></div>
                </div>
            </section>
            <section id='services' className="deliver full-size">
                <div className="deliver__stat-header">
                    <div className="deliver__stat-header__counter-container">
                        <div className="deliver__stat-header__counter">
                            <h2>5</h2>
                            <p>Years of experience</p>
                        </div>
                        <div className="deliver__stat-header__counter">
                            <h2>40+</h2>
                            <p>Projects built</p>
                        </div>
                        <div className="deliver__stat-header__counter">
                            <h2>50+</h2>
                            <p>Companies trust us</p>
                        </div>
                        <div className="deliver__stat-header__counter">
                            <h2>0</h2>
                            <p>Compromises</p>
                        </div>
                    </div>
                </div>
                <div className="deliver__container">
                    <div className="deliver__container__title">
                        <h1>What we deliver</h1>
                        <p>Discover a spectrum of services tailored to bring your vision to life.</p>
                    </div>
                    {(isMobile) ? (
                        <Carousel
                            showArrows={false}
                            showIndicators={false}
                            showThumbs={false}
                            showStatus={false}
                            centerMode={true}
                            className='deliver__container__carousel'
                        >
                            <div className="deliver__container__category">
                                <h3>Software</h3>
                                <p>We code solutions that power up your business. Delivering full-stack software that powers businesses, our code bridges robust backend systems with intuitive frontends for seamless functionality</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Design</h3>
                                <p>Our design philosophy marries functionality with visual elegance. We deliver bespoke digital designs that are both intuitive and striking, setting a new standard for user engagement and brand representation.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Ai/ML</h3>
                                <p>We architect systems that think and learn, positioning your business at the vanguard of innovation. We implement smart, data-driven solutions that enhance efficiency and unlock growth.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Research</h3>
                                <p>Grounding our projects in reality, we conduct thorough research to define precise KPIs, ensuring every product is crafted based on deep user insights and real-world data.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Branding</h3>
                                <p>Creating memorable brands with a cohesive identity that speaks to customers and stands out in the market. We shape perceptions with a cohesive branding strategy that encapsulates your values.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Design Support</h3>
                                <p>Design is an ongoing dialogue. We offer continuous support, ensuring that your brand's visual identity evolves with your business. From live design interventions to iterative updates.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Hardware</h3>
                                <p>Engineering prototypes and integrating cutting-edge hardware for cohesive tech ecosystems, ensuring your digital and physical assets work in unison.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Analisys & System Design</h3>
                                <p>Combining strategic planning with user-centric design, we meticulously analyze and prototype systems to ensure intuitive navigation and seamless user experiences.</p>
                            </div>
                        </Carousel>
                    ) : (
                        <div className="deliver__container__categories">
                            <div className="deliver__container__category">
                                <h3>Software</h3>
                                <p>We code solutions that power up your business. Delivering full-stack software that powers businesses, our code bridges robust backend systems with intuitive frontends for seamless functionality</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Design</h3>
                                <p>Our design philosophy marries functionality with visual elegance. We deliver bespoke digital designs that are both intuitive and striking, setting a new standard for user engagement and brand representation.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Ai/ML</h3>
                                <p>We architect systems that think and learn, positioning your business at the vanguard of innovation. We implement smart, data-driven solutions that enhance efficiency and unlock growth.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Research</h3>
                                <p>Grounding our projects in reality, we conduct thorough research to define precise KPIs, ensuring every product is crafted based on deep user insights and real-world data.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Branding</h3>
                                <p>Creating memorable brands with a cohesive identity that speaks to customers and stands out in the market. We shape perceptions with a cohesive branding strategy that encapsulates your values.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Design Support</h3>
                                <p>Design is an ongoing dialogue. We offer continuous support, ensuring that your brand's visual identity evolves with your business. From live design interventions to iterative updates.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Hardware</h3>
                                <p>Engineering prototypes and integrating cutting-edge hardware for cohesive tech ecosystems, ensuring your digital and physical assets work in unison.</p>
                            </div>
                            <div className="deliver__container__category">
                                <h3>Analisys & System Design</h3>
                                <p>Combining strategic planning with user-centric design, we meticulously analyze and prototype systems to ensure intuitive navigation and seamless user experiences.</p>
                            </div>
                        </div>
                    )}

                </div>
            </section>
            <section className="dev-process full-size">
                <div className="dev-process__content">
                    <h1>Development Process</h1>
                    <span>A closer look at our methods</span>
                    <div className="dev-process__stage-container">
                        <div className="dev-process__line" />
                        <div className="dev-process__stage">
                            <img src={hamsaIcon} className="dev-process__stage__icon" />
                            <h2>Discovery</h2>
                            <p>In the Discovery phase, we dive deep to understand your business goals, market needs, and user profiles. This strategic analysis informs our design and development, ensuring we build not just any solution, but the right one.</p>
                        </div>
                        <div className="dev-process__stage">
                            <img src={bezierIcon} className="dev-process__stage__icon" />
                            <h2>Design</h2>
                            <p>Design is where form meets function. Our approach integrates the latest UX/UI principles to create engaging and accessible designs. We focus on user journeys to ensure every interaction is intuitive and compelling.</p>
                        </div>
                        <div className="dev-process__stage">
                            <img src={terminalIcon} className="dev-process__stage__icon" />
                            <h2>Development</h2>
                            <p>Our agile teams employ best-in-class technologies to build scalable, secure, and robust applications. We maintain a balance of speed and quality to meet your timelines without compromising on performance.</p>
                        </div>
                        <div className="dev-process__stage">
                            <img src={launchIcon} className="dev-process__stage__icon" />
                            <h2>Launch</h2>
                            <p>We meticulously plan your go-live strategy, ensuring robust deployment, comprehensive QA, and support systems. Post-launch, we analyze user feedback and performance data to refine and enhance the product.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stack full-size">
                <div className="stack__content">
                    <div id="stack-title" className="stack__column">
                        <h1>Stack & Skills</h1>
                        <p>Our ethos is rooted in agility. We adapt, evolve, and grow with each project, embracing change as an ally. This agile spirit is woven into every facet of our work, ensuring that we are always at the forefront of technological progress, delivering solutions that are not just effective today but resilient for tomorrow</p>
                    </div>
                    {(isMobile) ? (
                        <div className="stack__icons">
                            <Marquee speed={20}>
                                <img height={93} src={stackRow1} />
                            </Marquee>
                            <Marquee direction='right' speed={15}>
                                <img height={93} src={stackRow2} />
                            </Marquee>
                            <Marquee speed={30}>
                                <img height={93} src={stackRow3} />
                            </Marquee>
                        </div>
                    ) : (
                        <div className="stack__icons" />
                    )}
                </div>
            </section>
            <section className="perfectionism full-size">
                <div className="perfectionism__content">
                    <h1>
                        Tech partnership backed up with
                        <b> perfectionism in mind</b>
                    </h1>
                    <div className="perfectionism__category-container">
                        <div className="perfectionism__category">
                            <h2>Agile methodology</h2>
                            <p>Adapting swiftly to the ever-changing digital landscape, our team excels in agile development. We meticulously shape products from conception to launch, ensuring they are as adaptive and dynamic as the technology that powers them. It’s a proof that your project remains flexible, timely, and above all, aligned with your strategic vision.</p>
                        </div>
                        <div className="perfectionism__category">
                            <h2>Pushing Boundaries</h2>
                            <p>Whether it's crafting user-centric designs or deploying sophisticated solutions, we empower your business across every digital frontier. Our commitment to innovation is the catalyst for transcending the ordinary, pushing boundaries to deliver excellence in every code committed, every pixel perfected</p>
                        </div>
                        <div className="perfectionism__category">
                            <h2>Client-Centric</h2>
                            <p>Every business is unique, and so are our solutions. Since that, our approach is deeply collaborative, ensuring that every project is a co-creation between our team and our clients. We believe in transparent communication, shared goals, and building solutions that fully encapsulate your vision and requirements.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="trusted full-size">
                <h1>Trusted by 50+ companies</h1>
                <div className="trusted__block">
                    {(isMobile || isPad) ? (
                        <Marquee
                            direction='left'
                            speed={20}
                        >
                            <img id='wallet-logo' src={WalletLogo} className="trusted__block__logo" />
                            <img src={FileCoinLogo} className="trusted__block__logo" />
                            <img id='rada-logo' src={RADALogo} className="trusted__block__logo" />
                            <img src={laMoukaLogo} className="trusted__block__logo" />
                            <img src={NanosondyLogo} className="trusted__block__logo" />
                            <img src={DreamBuildLogo} className="trusted__block__logo" />
                            <img src={InBoxLogo} className="trusted__block__logo" />
                            <img src={deosLogo} className="trusted__block__logo" />
                            <img src={srdcovkaLogo} className="trusted__block__logo" />
                            <img src={residenceLogo} className="trusted__block__logo" />
                        </Marquee>
                    ) : (
                        <div className="trusted__block__logos">
                            <img id='wallet-logo' src={WalletLogo} className="trusted__block__logo" />
                            <img src={FileCoinLogo} className="trusted__block__logo" />
                            <img id='rada-logo' src={RADALogo} className="trusted__block__logo" />
                            <img src={laMoukaLogo} className="trusted__block__logo" />
                            <img src={NanosondyLogo} className="trusted__block__logo" />
                            <img src={DreamBuildLogo} className="trusted__block__logo" />
                            <img src={InBoxLogo} className="trusted__block__logo" />
                            <img src={deosLogo} className="trusted__block__logo" />
                            <img src={srdcovkaLogo} className="trusted__block__logo" />
                            <img src={residenceLogo} className="trusted__block__logo" />
                        </div>
                    )}
                </div>
            </section>
            <section className="evolve">
                <h1>
                    Three ways to evolve <br />
                    <b> and grow your business</b>
                </h1>
                {(isMobile) ? (
                    <Carousel
                        showArrows={false}
                        showIndicators={false}
                        showThumbs={false}
                        showStatus={false}
                        centerMode={true}
                        centerSlidePercentage={100}
                        className='evolve__carousel'
                    >
                        <div className="evolve__category">
                            <img src={buildIcon} className="evolve__category__icon" />
                            <div className="evolve__category__content">
                                <h2>Develop new product</h2>
                                <h4>Let's connect if:</h4>
                                <ol>
                                    <li>You're envisioning a unique app.</li>
                                    <li>You require a dynamic website.</li>
                                    <li>Custom software is your need.</li>
                                </ol>
                            </div>
                            <div onClick={() => navigate('/form')} className="evolve__category__button">
                                Schedule a Strategy Session
                                <div className="icon" />
                            </div>
                        </div>
                        <div className="evolve__category">
                            <img src={boxesIcon} className="evolve__category__icon" />
                            <div className="evolve__category__content">
                                <h2>Validate your ideas</h2>
                                <h4>We should talk if:</h4>
                                <ol>
                                    <li>You seek clarity for your ideas.</li>
                                    <li>A prototype needs to come to life.</li>
                                    <li>User feedback is crucial for your project.</li>
                                </ol>
                            </div>
                            <div onClick={() => navigate('/form')} className="evolve__category__button">
                                Arrange a Concept Review
                                <div className="icon" />
                            </div>
                        </div>
                        <div className="evolve__category">
                            <img src={improveIcon} className="evolve__category__icon" />
                            <div className="evolve__category__content">
                                <h2>Improve your product</h2>
                                <h4>Time to discuss when:</h4>
                                <ol>
                                    <li>Your metrics need fine-tuning.</li>
                                    <li>You aim for continuous evolution.</li>
                                    <li>Ongoing support is what you seek.</li>
                                </ol>
                            </div>
                            <div onClick={() => navigate('/form')} className="evolve__category__button">
                                Book a Product Consultation
                                <div className="icon" />
                            </div>
                        </div>
                    </Carousel>
                ) : (
                    <div className="evolve__category-container">
                        <div className="evolve__category">
                            <img src={buildIcon} className="evolve__category__icon" />
                            <div className="evolve__category__content">
                                <h2>Develop new product</h2>
                                <h4>Let's connect if:</h4>
                                <ol>
                                    <li>You're envisioning a unique app.</li>
                                    <li>You require a dynamic website.</li>
                                    <li>Custom software is your need.</li>
                                </ol>
                            </div>
                            <div onClick={() => navigate('/form')} className="evolve__category__button">
                                Schedule a Strategy Session
                                <div className="icon" />
                            </div>
                        </div>
                        <div className="evolve__category">
                            <img src={boxesIcon} className="evolve__category__icon" />
                            <div className="evolve__category__content">
                                <h2>Validate your ideas</h2>
                                <h4>We should talk if:</h4>
                                <ol>
                                    <li>You seek clarity for your ideas.</li>
                                    <li>A prototype needs to come to life.</li>
                                    <li>User feedback is crucial for your project.</li>
                                </ol>
                            </div>
                            <div onClick={() => navigate('/form')} className="evolve__category__button">
                                Arrange a Concept Review
                                <div className="icon" />
                            </div>
                        </div>
                        <div className="evolve__category">
                            <img src={improveIcon} className="evolve__category__icon" />
                            <div className="evolve__category__content">
                                <h2>Improve your product</h2>
                                <h4>Time to discuss when:</h4>
                                <ol>
                                    <li>Your metrics need fine-tuning.</li>
                                    <li>You aim for continuous evolution.</li>
                                    <li>Ongoing support is what you seek.</li>
                                </ol>
                            </div>
                            <div onClick={() => navigate('/form')} className="evolve__category__button">
                                Book a Product Consultation
                                <div className="icon" />
                            </div>
                        </div>
                    </div>
                )}
            </section>
            <section className="tech-team">
                <div className="tech-team__title">
                    <h1>Get your own tech team</h1>
                    <p>Entire technical department of 8 specialists <br />
                        for your company with a price of one developer.</p>
                </div>
                <div className="tech-team__period">
                    <div className="tech-team__period__item tech-team__period__item__active">
                        Monthly
                    </div>
                    <div className="tech-team__period__item">
                        Yearly
                    </div>
                </div>
                {isMobile ? (
                    <Carousel
                        showArrows={false}
                        showIndicators={false}
                        showThumbs={false}
                        showStatus={false}
                        centerMode={true}
                        centerSlidePercentage={100}
                        className='tech-team__carousel'
                    >
                        <div className="tech-team__plan">
                            <div id="plant" className="icon" />
                            <h2>Lite</h2>
                            <p>Starter plan for small businesses who wants to enter the digital space.</p>
                            <div className="price">
                                <span>€1999</span>
                                <p>per month</p>
                            </div>
                            <div className="tech-team__plan__perks">
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    2 hours per day
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Team of 8 specialists
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Full-stack project support
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Project Management
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Regular Updates
                                </div>
                            </div>
                            <div onClick={() => navigate('/form')} className="btn-start">Get Started</div>
                        </div>
                        <div className="tech-team__plan">
                            <div id="rocket" className="icon" />
                            <h2>Pro</h2>
                            <p>Unleash the Power of Your Business and scale with Pro Plan.</p>
                            <div className="price">
                                <span>€3499</span>
                                <p>per month</p>
                            </div>
                            <div className="tech-team__plan__perks">
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    4 hours per day
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    All features of Lite Plan
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Support Team
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Design Support Team
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    External Experts on request
                                </div>
                            </div>
                            <div onClick={() => navigate('/form')} className="btn-start">Get Started</div>
                        </div>
                        <div className="tech-team__plan">
                            <div id="business" className="icon" />
                            <h2>Enterprice</h2>
                            <p>Take Your Business to the Next Level with Business Plan.</p>
                            <div className="price">
                                <span>Individual</span>
                                <p>price</p>
                            </div>
                            <div className="tech-team__plan__perks">
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Up to 24 hours per day
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    All features of Pro Plan
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Advanced Project Management
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    24/7 Support Team
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Scaling to any size
                                </div>
                            </div>
                            <div onClick={() => navigate('/form')} className="btn-start">Contact</div>
                        </div>
                    </Carousel>
                ) : (
                    <div className="tech-team__plan-container">
                        <div className="tech-team__plan">
                            <div id="plant" className="icon" />
                            <h2>Lite</h2>
                            <p>Starter plan for small businesses who wants to enter the digital space.</p>
                            <div className="price">
                                <span>€1999</span>
                                <p>per month</p>
                            </div>
                            <div className="tech-team__plan__perks">
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    2 hours per day
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Team of 8 specialists
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Full-stack project support
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Project Management
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Regular Updates
                                </div>
                            </div>
                            <div onClick={() => navigate('/form')} className="btn-start">Get Started</div>
                        </div>
                        <div className="tech-team__plan">
                            <div id="rocket" className="icon" />
                            <h2>Pro</h2>
                            <p>Unleash the Power of Your Business and scale with Pro Plan.</p>
                            <div className="price">
                                <span>€3499</span>
                                <p>per month</p>
                            </div>
                            <div className="tech-team__plan__perks">
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    4 hours per day
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    All features of Lite Plan
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Support Team
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Design Support Team
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    External Experts on request
                                </div>
                            </div>
                            <div onClick={() => navigate('/form')} className="btn-start">Get Started</div>
                        </div>
                        <div className="tech-team__plan">
                            <div id="business" className="icon" />
                            <h2>Enterprice</h2>
                            <p>Take Your Business to the Next Level with Business Plan.</p>
                            <div className="price">
                                <span>Individual</span>
                                <p>price</p>
                            </div>
                            <div className="tech-team__plan__perks">
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Up to 24 hours per day
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    All features of Pro Plan
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Advanced Project Management
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    24/7 Support Team
                                </div>
                                <div className="tech-team__plan__perks__item">
                                    <div className="icon-check" />
                                    Scaling to any size
                                </div>
                            </div>
                            <div onClick={() => navigate('/form')} className="btn-start">Contact</div>
                        </div>
                    </div>
                )}
            </section>
            <section id='content-hub' className="social-media full-size">
                <div className="social-media__container">
                    <h1>
                        Elevate Your Venture
                        <b> With Our Insights</b>
                    </h1>
                    {(isMobile) ? (
                        <Carousel
                            showArrows={false}
                            showIndicators={false}
                            showThumbs={false}
                            showStatus={false}
                            centerMode={true}
                            centerSlidePercentage={100}

                            className='social-media__carousel'
                        >
                            <div>
                                <img src={Post1} className="social-media__post" />
                            </div>
                            <div>
                                <img src={Post2} className="social-media__post" />
                            </div>
                            <div>
                                <img src={Post3} className="social-media__post" />
                            </div>
                        </Carousel>
                    ) : (
                        <div className="social-media__posts">
                            <img src={Post1} className="social-media__post" />
                            <img src={Post2} className="social-media__post" />
                            <img src={Post3} className="social-media__post" />
                        </div>
                    )}
                </div>
            </section>
            <section className="discuss">
                <div className="logo" />
                <h1>Let’s discuss on how we can help you</h1>
                <p>
                    Before starting work, we ask clients to provide brief responses to a few questions. <br />
                    This helps us understand how we can assist you best.
                </p>
                <div onClick={() => navigate('/form')} className="discuss__button">
                    <span>Contact</span>
                    <div className="icon" />
                </div>
            </section>
        </div>
    );
}

export default MainPage;