import { Link } from 'react-router-dom';
import './footer.scss';

const Footer = () => {
    const mobile = window.innerWidth < 768;

    if (!mobile) return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__vector" />
                <div className="footer__menu">
                    <div className="footer__menu__left">
                        <div className="footer__menu__column">
                            <Link className="footer__menu__column__item">DeLabs</Link>
                            <Link className="footer__menu__column__item">Services</Link>
                            <Link className="footer__menu__column__item">Projects</Link>
                            <Link className="footer__menu__column__item">Content-hub</Link>
                        </div>
                        <div className="footer__menu__row">
                            <div className="footer__menu__row__item">Fb</div>
                            <div className="footer__menu__row__item">In</div>
                            <div className="footer__menu__row__item">Tg</div>
                        </div>
                    </div>
                    <div className="footer__menu__right">
                        <div className="footer__menu__column">
                            <a href='tel:+420602331256' className="footer__menu__column__contact ">+420 602 331 256</a>
                            <a href='mailto:info@delabs.dev' className="footer__menu__column__contact">info@delabs.dev</a>
                        </div>
                        <div className="footer__menu__row">
                            <div className="footer__menu__row__item">En</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__line" />
            <div className="footer__row">
                <div className="footer__row__item">© Digital agency Delabs</div>
                <div className="footer__row__links">
                    <div id='privacy' className="footer__row__item">Privacy policy</div>
                    <Link to='/terms' id='terms' className="footer__row__item">Terms and conditions</Link>
                </div>
            </div>
        </div>
    );
    else return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__container__segment">
                    <div className="footer__container__section">
                        <div className="footer__container__section__links">
                            <div className="footer__container__section__links__item">Delabs</div>
                            <div className="footer__container__section__links__item">Services</div>
                            <div className="footer__container__section__links__item">Projects</div>
                            <div className="footer__container__section__links__item">Content-hub</div>
                        </div>
                        <div className="footer__container__section__links-row">
                            <div className="footer__container__section__links-row__item">Fb</div>
                            <div className="footer__container__section__links-row__item">In</div>
                            <div className="footer__container__section__links-row__item">Tg</div>
                        </div>
                    </div>
                    <div id='footer-upper' className="footer__container__section">
                        <div className="footer__container__section__links-column">
                            <a href='tel:+420602331256' className="footer__container__section__links-column__item">+420 602 331 256</a>
                            <a href='mailto:info@delabs.dev' className="footer__container__section__links-column__item">info@delabs.dev</a>
                        </div>
                        <div className="footer__vector" />
                        <div className="footer__container__section__links-row">
                            <div className="footer__container__section__links-row__item">En</div>
                        </div>
                    </div>
                </div>
                <div className="footer__line" />
                <div className="footer__container__section">
                    <div className="footer__container__section__links-row">
                        <div id='privacy' className="footer__container__section__links-row__item">Privacy policy</div>
                        <Link to='/terms' id='terms' className="footer__container__section__links-row__item">Terms and conditions</Link>
                    </div>
                    <div className="footer__container__section__links-row">
                        <div id='delabs' className="footer__container__section__links-row__item">© Digital agency Delabs</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;