import './cookieConsent.scss';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const CookieConsent = () => {
    const [consentGiven, setConsentGiven] = useState(
        localStorage.getItem('cookieConsent')
    );

    const handleConsent = () => {
        localStorage.setItem('cookieConsent', 'true');
        setConsentGiven(true);
    };

    const handleDecline = () => {
        localStorage.setItem('cookieConsent', 'false');
        setConsentGiven(true);
    }

    return (
        <div className={`cookie-consent ${consentGiven ? 'hidden' : ''}`}>
            <div className="cookie-consent__header">
                <h2>Your Privacy</h2>
                <div className="cookie-consent__header__icon" />
            </div>
            <p>We use cookies to ensure that we give you the best experience on our website. <Link to='/cookies'>Read cookies policies.</Link> </p>
            <div className="cookie-consent__buttons">
                <button onClick={handleDecline}>Decline</button>
                <button onClick={handleConsent}>Accept</button>
            </div>
        </div>
    );
};

//google-site-verification=MRdZT5rfcPYur47IrELesu3Wf9hXa203GlWlf_0Z7hQ

export default CookieConsent;
