import { useNavigate } from 'react-router-dom';
import './errorPage.scss';

const ErrorPage = () => {

    const navigate = useNavigate();

    return (
        <div className="error">
            <div className="error__background">400</div>
            <h1 className="error__title">500</h1>
            <p className="error__text">
                Something went wrong, try again later
            </p>
            <div onClick={() => navigate('/')} className="error__button">
                <span>Go to main page</span>
                <div className="error__button__icon" />
            </div>
        </div>
    )
}

export default ErrorPage;