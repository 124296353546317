import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './header.scss';

import menuIcon from '../../../assets/images/menu.svg';
import closeIcon from '../../../assets/images/cross.svg';
import handleAnchorClick from '../../../utils/handleAnchorLink';

const Header = () => {
    const platform = window.navigator.platform
    const navigate = useNavigate();
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    const handleMenuClick = () => {
        setIsMenuOpened(!isMenuOpened);
    }

    // const handleAnchorClick = (id) => {
    //     if (isMenuOpened) setIsMenuOpened(false)

    //     const element = document.getElementById(id);
    //     element.scrollIntoView({ behavior: 'smooth' });
    // }

    return (
        <div
            style={{ height: isMenuOpened ? '100vh' : '32px' }}
            className="header">
            <div className="header__container">
                <div className="header__left">
                    <div onClick={() => navigate('/')} className="header__title">
                        <h2>Delabs</h2>
                        <div className="logo" />
                    </div>
                    <span onClick={() => navigate('/form')} id="btn-become" className="header__btn">+ Become a client</span>
                </div>
                <div className="header__right">
                    <div className="header__right__container">
                        <Link to='#services' onClick={() => handleAnchorClick('services')} className="header__btn">Services</Link>
                        <Link to="/cases" className="header__btn">Cases</Link>
                        <Link to='#content-hub' onClick={() => handleAnchorClick('content-hub')} className="header__btn">Content-hub</Link>
                    </div>
                    <img onClick={() => setIsMenuOpened(!isMenuOpened)} src={isMenuOpened ? closeIcon : menuIcon} className="header__menu-icon" />
                </div>
            </div>
            {isMenuOpened &&
                <div className='header__menu'>
                    <div className="header__menu__links">
                        <Link onClick={handleMenuClick} to="" className="header__menu__link">About us</Link>
                        <Link onClick={handleMenuClick} to="/cases" className="header__menu__link">Cases</Link>
                        <Link onClick={() => handleAnchorClick('services', handleMenuClick)} to="#services" className="header__menu__link">Services</Link>
                        <Link onClick={() => handleAnchorClick('content-hub', handleMenuClick)} to="#content-hub" className="header__menu__link">Content-hub</Link>
                        <Link onClick={handleMenuClick} to="/form" className="header__menu__link">Become a client</Link>
                        <Link onClick={handleMenuClick} to="" className="header__menu__link">Contacts</Link>
                    </div>
                    <div className="header__menu__language">
                        <span onClick={handleMenuClick} className='header__menu__lang active'>Eng</span>
                        <span onClick={handleMenuClick} className='header__menu__lang'>Czk</span>
                    </div>
                    <div className="header__menu__contacts">
                        <span className="header__menu__contact">+420 602 331 256</span>
                        <span className="header__menu__contact">info@deos.cc</span>
                    </div>
                    <p className="header__menu__rights">
                        @ 2024 DeLabs. All rights reserved.<br />
                        <Link onClick={handleMenuClick} to="/terms">Terms and conditions</Link>
                    </p>
                </div>
            }
        </div >
    )
}

export default Header;