import React, { useState } from 'react';
import './formSent.scss';
import { useNavigate } from 'react-router-dom';

const FormSent = () => {
    const navigate = useNavigate();

    return (
        <div className="form-success">
            <div className="logo" />
            <h1>Thank you! </h1>
            <p>
                We recieved your application and we will contact you soon
            </p>
            <div onClick={() => navigate('/')} className="form-success__button">
                <span>Go back to the main page</span>
                <div className="icon" />
            </div>
        </div>
    )
}

export default FormSent;