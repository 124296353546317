import { ProjectCard } from "../../components"
import "../main/mainPage.scss";
import './casesPage.scss';

import browserImage from '../../assets/images/projects/browser.png';
import wallet from '../../assets/images/projects/wallet.png';
import notes from '../../assets/images/projects/ai-notes.png';
import cleaningApp from '../../assets/images/projects/cleaning-app.png';
import cloudProvider from '../../assets/images/projects/cloud-provider.png';
import gameStore from '../../assets/images/projects/game-store.png';
import gamingPlatform from '../../assets/images/projects/gaming-platform.png';
import marketplace from '../../assets/images/projects/marketplace.png';
import messanger from '../../assets/images/projects/messanger.png';
import socialNetwork from '../../assets/images/projects/social-network.png';
import taskAi from '../../assets/images/projects/task-ai.png';
import websiteDeos from '../../assets/images/projects/website-deos.png';

import customDevice from '../../assets/images/industrial-design/custom-device.png';
import industrialControllerCase from '../../assets/images/industrial-design/industrial-controller-case.png';
import multitool from '../../assets/images/industrial-design/multitool.png';
import reductor from '../../assets/images/industrial-design/reductor.png';
import { useNavigate } from "react-router-dom";

const CasesPage = () => {
    const navigate = useNavigate();

    return (
        <div className="cases">
            <section className="title">
                <div className="title__header">
                    <h1>Our Cases</h1>
                    <span>(15)</span>
                </div>
                <p className="title__paragraph">
                    From our Prague base, we weave technology and design into digital artistry. With each project, we blend advanced tech with intuitive design, crafting experiences that resonate with users and set industry benchmarks.
                </p>
            </section>
            <section className="projects">
                <h1 className='projects__title'>Software Development</h1>
                <div className="projects__card-container">
                    <ProjectCard
                        key={1}
                        title='Ai Browser'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Software package'
                        large
                        img={browserImage}
                    />
                    <ProjectCard
                        key={2}
                        title='Wallet'
                        category='Full-stack development'
                        description='Online service'
                        img={wallet}
                    />
                    <ProjectCard
                        key={3}
                        title='3D assets marketplace'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Engineering, 3d modelling'
                        img={marketplace}
                    />
                    <ProjectCard
                        key={4}
                        title='Social Network'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Cross-platform app'
                        large
                        img={socialNetwork}
                    />
                    <ProjectCard
                        key={5}
                        title='Cleaning App'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Online service'
                        img={cleaningApp}
                    />
                    <ProjectCard
                        key={6}
                        title='Ai-powered notes'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Online service'
                        img={notes}
                    />
                    <ProjectCard
                        key={7}
                        title='Gaming Platform'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Software package'
                        large
                        img={gamingPlatform}
                    />
                    <ProjectCard
                        key={8}
                        title='Distributed cloud provider'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Online service'
                        img={cloudProvider}
                    />
                    <ProjectCard
                        key={9}
                        title='Game Store'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Online service'
                        img={gameStore}
                    />
                    <ProjectCard
                        key={10}
                        title='Messanger'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Cross-platform development'
                        large
                        img={messanger}
                    />
                    <ProjectCard
                        key={11}
                        title='Task.ai'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Online service'
                        img={taskAi}
                        color='#292929'
                    />
                    <ProjectCard
                        key={12}
                        title='Website deos.cc'
                        category='UX / UI'
                        subcategory='Full-stack development'
                        description='Online service'
                        img={websiteDeos}
                        color='#292929'
                    />
                </div>
            </section>
            <section className="industrial-design">
                <h1>Industrial design</h1>
                <div className="industrial-design__card-container">
                    <ProjectCard
                        key={13}
                        title='Industrial controller case'
                        category='Hardware Device'
                        description='Engineering, 3d modelling'
                        img={industrialControllerCase}
                        color='#292929'
                    />
                    <ProjectCard
                        key={14}
                        title='Reductor'
                        category='Hardware Device'
                        description='Engineering, 3d modelling'
                        img={reductor}
                        color='#292929'
                    />
                    <ProjectCard
                        key={15}
                        title='Multitool'
                        category='Hardware Device'
                        description='Engineering, 3d modelling'
                        img={multitool}
                        color='#292929'
                    />
                    <ProjectCard
                        key={16}
                        title='Custom device'
                        category='Hardware Device'
                        description='Engineering, 3d modelling'
                        img={customDevice}
                        color='#292929'
                    />
                </div>
            </section>
            <section className="discuss">
                <div className="logo" />
                <h1>Let’s discuss on how we can help you</h1>
                <p>
                    Before starting work, we ask clients to provide brief responses to a few questions. <br />
                    This helps us understand how we can assist you best.
                </p>
                <div onClick={() => navigate('/form')} className="discuss__button">
                    <span>Contact</span>
                    <div className="icon" />
                </div>
            </section>
        </div>
    )
}

export default CasesPage;