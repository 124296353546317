import React, { useState } from 'react';
import './formPage.scss';
import { useNavigate } from 'react-router-dom';

const FormPage = () => {
    const [projectType, setProjectType] = useState(0);
    const [budget, setBudget] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comments, setComments] = useState('');

    const navigate = useNavigate();

    const projectTypes = [
        'Website & Landing Page',
        'Custom Full-Stack development',
        'Mobile App',
        'UX/UI',
        'Research & Analysis',
        'eCommerce',
        'Automatization',
        'Ai/Ml development',
        'Internal Project',
        'Hardware Design',
        'Other'
    ];

    const budgetOptions = [
        'Below €10.000',
        '€10.000 - €25.000',
        '€25.000 - €50.000',
        'More €50.000'
    ];

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleCommentsChange = (event) => {
        setComments(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        fetch('https://deos.cc:4000/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                projectType: projectTypes[projectType],
                budget: budgetOptions[budget],
                name,
                email,
                comments
            })
        }).then(() => {
            navigate('/form-sent');
        }).catch((error) => {
            navigate('/form-error');
        });
    }

    return (
        <div className="form">
            <div className="form__title">
                <h1>Let’s do some magic!</h1>
                <p>Contact us and don’t be shy. We will support and care about everything.</p>
            </div>
            <div className="form__type">
                <h2>What project do you have?</h2>
                <div className="picker picker--vertical">
                    {projectTypes.map((type, index) => (
                        <div className={`picker__item ${projectType === index ? 'active' : ''}`} onClick={() => setProjectType(index)}>{type}</div>
                    ))}
                </div>
            </div>
            <div className="form__budget">
                <h2>What is your estimated budget?</h2>
                <div className="picker picker--horizontal">
                    {budgetOptions.map((option, index) => (
                        <div className={`picker__item ${budget === index ? 'active' : ''}`} onClick={() => setBudget(index)}>{option}</div>
                    ))}
                </div>
            </div>
            <div className="form__details">
                <h2>Contact Details</h2>
                <div className="form__details__fields">
                    <input onChange={handleNameChange} placeholder='Your Name' type="text" />
                    <input onChange={handleEmailChange} placeholder='Email, phone number or messenger' type="text" />
                    <input onChange={handleCommentsChange} placeholder='Any comments?' type="text" />
                </div>
            </div>
            <div onClick={handleSubmit} className="form__submit">
                Send request
            </div>
            <div className="form__note">
                <p>By clicking on the button, you consent to the processing of personal data and agree to the privacy policy</p>
            </div>
        </div>
    );
}

export default FormPage;